<template lang="html">
  <div class="all">
    <div class="info-form pixels-form" :class='{error: !isNaN(errorInPixelIndex) && index === errorInPixelIndex}' v-for='pixel, index in pixels' v-if='pixels'>
      <div class="fields-sec">
        <FormField :disabled='!userHasPermission("placements.edit")' :inline='1' :value='pixel.name' placeholder="Offer Name" labelText='Offer Name' :onUpdate='e => { pixel.name = e }' />
        <div class="pixel-url-type">
          <label class="pixel-url-type-t" >Insert URL as: </label>
          <div class="form-inputs">
            <label :for='"spe2" + index + pixel.htmlID'>Custom URL</label>
            <input :disabled='!userHasPermission("placements.edit")' :id='"spe2" + index + pixel.htmlID' type="radio" v-model='pixel.showURLType' value="custom" @change='pixelURLTypeChange(pixel)'>
          </div>
          <div class="form-inputs">
            <label :for='"spe3" + index + pixel.htmlID'>Template</label>
            <input :disabled='!userHasPermission("placements.edit")' :id='"spe3" + index + pixel.htmlID' type="radio" v-model='pixel.showURLType' value="template" @change='pixelURLTypeChange(pixel)'>
          </div>
        </div>
        <div class="form-inputs" v-if='pixel.showURLType === "custom"'>
          <FormField :disabled='!userHasPermission("placements.edit")' :inputStyle='{width: "700px", fontSize: "13px"}' :inline='1' :value='pixel.url' placeholder="Custom URL" labelText='Custom URL' :onUpdate='e => { pixel.url = e }' />
        </div>
        <template v-else>
          <FormField :def='0' :inline='1' placeholder="URL Template" labelText='URL Template'>
            <select :disabled='!userHasPermission("placements.edit")' v-model='pixel.template' class="def" name="" @change='onTemplateChange'>
              <option>Choose Template</option>
              <option v-for='option in templates' :value='option'>{{option.name}}</option>
            </select>
          </FormField>
          <FormField :disabled='!userHasPermission("placements.edit")' :inline='1' :value='pixel.template_vars.pid' placeholder="Pub ID" labelText='Pub ID' :onUpdate='e => { pixel.template_vars.pid = e }' />
          <FormField :disabled='!userHasPermission("placements.edit")' :inline='1' :value='pixel.template_vars.offerid' placeholder="Offer ID" labelText='Offer ID' :onUpdate='e => { pixel.template_vars.offerid = e }' />
          <p class="px-result" v-show='pixel.template'>Result URL: {{buildPixelURL(pixel)}}</p>
        </template>
      </div>
      <div class="btns-23" v-show='userHasPermission("placements.edit")'>
        <i class="fa fa-trash trash-btn" aria-hidden="true" @click='removePixel(pixel)'></i>
      </div>
    </div>
    <div class="btns-23" v-show='userHasPermission("placements.edit")'>
      <button type="button" class="btn _btn-white add-pixel-btn" @click='addPixel'>
        <i class="fa fa-plus" aria-hidden="true"></i>
        Pixel
      </button>
    </div>
  </div>
</template>

<script>
import placementUtils from './utils.placements'
export default {
  props: {
    defaultPid: {},
    defaultTemplateId: {},
    pixels: {
      type: Array,
      default() { return [] }
    },
    scriptTag: {},
    emptyPixels: {
      type: Number,
      default: 0
    },
    errorInPixelIndex: {
      type: Number
    }
  },
  computed: {
    defaultP() {
      return !isNaN(this.defaultPid) && this.defaultPid > 0 ? this.defaultPid : ""
    },
    defaultTemplateI() {
      return !isNaN(this.defaultTemplateId) && this.defaultTemplateId > 0 ? this.defaultTemplateId : ""
    }
  },
  watch: {
    defaultP(v) {
      this.pixelForm.template_vars.pid = v
    }
  },
  data() {
    let pixelForm = {
      name: null,
      cap: null,
      url: null,
      template: null,
      template_vars: {
        pid: "652",
        offerid: ""
      },
      showURLType: "template",
      htmlID: 0
    }

    return {
      pixelForm,
      templates: [],
      default_template_id: 0,
      default_template: {},
      showURLType: "template",
    }
  },

  async created() {
    await this.$store.dispatch("GET_TM_TEMPLATES").then(templates => {
      templates = templates.filter(v => v.name === "Internal SL" || v.name === "Internal Offer" || v.name === "Internal SL V2")
      this.templates = templates
      if (this.templates !== null) {
        this.templates.filter(obj => {
          if (obj.name === 'Internal SL V2') {
            this.default_template = obj
            this.default_template_id = obj.id
            return 
          }
        })
      }
      this.addEmptyPixels(this.emptyPixels)

      if(!this.pixels) { return }
      this.pixels.forEach(px => {
        if (typeof px.template_id === 'undefined') {
          px.template_id = this.default_template_id
        }
        px.showURLType = px.template_id ? "template" : "custom"
        if(px.template_id) {
          px.template = this.templates.find(v => v.id == px.template_id)
        }
      })
    })
  },
  methods: {
    onTemplateChange() {
      this.$forceUpdate()
    },
    addEmptyPixels(n) {
      if(isNaN(n)) { return }
      for(let i = 0; i < n; i++) {
        this.addPixel()
      }
    },
    addPixel() {
      let obj = {
        template: this.default_template,
        showURLType: "template",
        default_template_id: this.default_template_id,
        template_vars: {
          pid: this.pixelForm.template_vars.pid,
          offerid: '',
        },
      }
      this.pixels.push(Object.assign({}, this.pixelForm, obj))

    },
    removePixel(pixel) {
      let pixels = this.pixels
      let i = pixels.indexOf(pixel)
      pixels.splice(i, 1)
    },
    buildPixelURL: placementUtils.buildPixelURL,
    pixelURLTypeChange(pixel) {
      Object.assign(pixel, {
        url: null,
        template: "",
        template_vars: {
          pid: this.defaultP,
          offerid: ""
        },
      })
    }
  }
}
</script>

<style lang="css" scoped>
.pixel-url-type {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 12px;
  color: #525252;
  font-size: 13px;
}
.pixel-url-type > *:last-child {
  margin-left: 20px;
}
.pixel-url-type-t {
  width: 125px;
}
.pixels-form {
  margin-bottom: 10px;
}
.pixels-form.error {
  border: 1px solid red;
}
.pixels-h7 {
  margin-top: 15px;
  display: block;
  margin-bottom: 10px;
}
.add-pixel-btn {
  font-size: 12px;
}
.info-form {
  border: 1px solid gainsboro;
  padding: 8px 11px;
  background: white;
}
.fields-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fields-sec > * {
  margin-right: 10px;
}
.px-result {
  margin-top: 5px;
  border: 1px solid gainsboro;
  padding: 5px;
  word-break: break-all;
  background: #fdfdfd;
  margin-bottom: 11px;
  width: 100%;
  font-size: 14px;
}
.btns-23 {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
</style>
