import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// Containers
import Full from '@/containers/Full'
import Admin from '@/views/admin/'
import Home from '@/views/Home'
import Insights from '@/views/Insights'

// Views
import Auth from '@/views/auth/'
import Management from '@/views/management/'
import Reports from '@/views/reports/'


import Domains from '@/views/domains'
import Unavailable from '@/views/Unavailable.vue'
import Page404 from '@/views/Page404.vue'
import TestPage from '@/views/TestPage.vue'
import SmartLinks from '@/views/apps/smartlinks'
import ScriptTags from '@/views/apps/script-tags'
import Bl from '../views/apps/script-tags/views/Bl.stags.vue'
import AffiseVars from '@/views/management/affise_vars'
import SourcesControl from '@/views/management/sources'
import ManualPostback from '@/views/management/manual-postback/index.vue'
import UpdateManualRevenue from '@/views/management/update-manual-revenue/index.vue'
import UpdateManualRevenueEdit from '@/views/management/update-manual-revenue/edit.vue'
import UpdateManualRevenueHistory from '@/views/management/update-manual-revenue/history.vue'
import DspSchedule from '../views/dsp-schedule/Home.dsp-schedule.vue'
import EditDspSchedule from '../views/dsp-schedule/Edit.dsp-schedule.vue'
import HomeDash from '../views/dash/Home.dash.vue'
import HomeDashGlobal from '../views/dash/Home.dash_global.vue'
import HomeDashAfSeat from '../views/dash/Home.dash_af_seat.vue'
import HomeDashAdv from '../views/dash/Home.dash_adv.vue'
import HomeDashSeats from '../views/dash/Home.dash_seats.vue'
import BundleIDs from '../views/apps/bundle-ids'
import Store from '@/store'
const state = Store.state

import RedirectComponent from '@/views/Redirect.vue'
import management from '../views/management'

let router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ }),
  routes: [
    {
      path: '/auth',
      name: 'Auth',
      redirect: '/auth/log-in',
      component: { render: c => c('router-view') },
      children: [
        {
          path: 'log-in',
          name: 'Login',
          component: Auth.Login,
          meta: { authed: false}
        },
        {
          path: 'sign-up',
          name: 'SignUp',
          component: Auth.SignUp,
          meta: { authed: false}
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: Auth.ForgotPassword,
          meta: { authed: false}
        },
        {
          path: 'resetlink/:token',
          name: 'ResetLink',
          component: Auth.ResetLink
        }
      ]
    },
    {
      path: '/',
      meta: {
        authed: true,
        skip: true
      },
      component: Full,
      redirect: {name: 'Home'},
      children: [
        {
          path: 'home',
          name: 'Home',
          component: Home
        },
        {
          path: 'insights',
          name: 'Insights',
          component: Insights
        },
        {
          path: 'admin',
          name: 'Admin',
          meta: {
            permission: "users"
          },
          component: { render: c => c('router-view') },
          children: [
            {
              path: 'edit-user',
              name: 'AdminEditUser',
              component: Admin.EditUser
            },
            {
              path: 'edit-roles',
              name: 'AdminEditRoles',
              component: Admin.EditRoles,
              meta: {
                permission: "roles"
              }
            },
            {
              path: 'edit-permissions',
              name: 'AdminEditPermissions',
              component: Admin.EditPermissions,
              meta: {
                permission: "permissions"
              }
            },
            {
              path: '/media-analyst/dsp-schedule',
              name: 'dsp-schedule',
              component: DspSchedule
            },
            {
              path: '/media-analyst/dsp-schedule/edit',
              name: 'edit-dsp-schedule',
              component: EditDspSchedule
            },
            {
              path: '/admin/dash',
              name: 'AdminDash',
              component: HomeDash
            },
            {
              path: '/admin/dash_global_details',
              name: 'AdminDashGlobal',
              component: HomeDashGlobal
            },
            {
              path: '/admin/dash_adv_details',
              name: 'AdminDashGlobal',
              component: HomeDashAdv
            },
            {
              path: '/admin/dash_seats_details',
              name: 'AdminDashGlobal',
              component: HomeDashAfSeat
            }
          ]
        },
        {
          name: 'Management',
          path: '/man',
          component: { render: c => c('router-view') },
          meta: {
            skip: true
          },
          children: [
            {
              path: "pid-list",
              name: "pid-list",
              component:  Management.PidBankList.Home
            },
            {
              path: "pid-pool",
              name: "pid-pool",
              component: Management.PidBank.Home
            },
            {
              path: "pid-pool-lists",
              name: "pid-pool-lists",
              component: Management.PidBankList.Home
            },
            {
              path: "pid-pool-lists-details",
              name: "pid-pool-lists-details",
              component: Management.PidBankListsDetails.Home
            },
            {
              path: "pid-pool-bulk",
              name: "pid-pool-bulk",
              component: Management.PidBankBulk.Home
            },
            {
              path: "sync_postback",
              name: "sync_postback",
              component: Management.SyncPostback.Home
            },
            {
              path: "bundle-pool",
              name: "bundle-pool",
              component: Management.BundlePool.Home
            },
            {
              path: "bundle-pool-edit/:id",
              name: "bundle-pool-edit",
              component: Management.BundlePoolEdit.Home
            },
            {
              path: "bundle-pool-bulk/:id",
              name: "bundle-pool-bulk",
              component: Management.BundlePoolBulk.Home
            },
            {
              path: "performance-overview",
              name: "campaigns",
              component: Management.Campaigns.Home,
            },
            {
              path: "manual-postback",
              name: "ManualPostback",
              component: ManualPostback,
            },
            {
              path: "update-manual-revenue",
              name: "UpdateManualRevenue",
              component: UpdateManualRevenue,
            },
            {
              path: "update-manual-revenue-edit",
              name: "UpdateManualRevenueEdit",
              component: UpdateManualRevenueEdit,
            },
            {
              path: "update-manual-revenue-history",
              name: "UpdateManualRevenueHistory",
              component: UpdateManualRevenueHistory,
            },
            {
              path: "banners/:filterType",
              name: "PlacementBanners",
              component: Management.Banners.Home,
              meta: {
                permission: "placements"
              }
            },
            {
              path: "advertisers-offers-whitelist",
              name: "AdvertiserOfferWhitelist",
              component: Management.AdvertiserOfferWhitelist,
            },
            {
              path: "vars-offers",
              name: "VarsOffers",
              component: AffiseVars.Home,
              meta: {
                skip: true,
                permission: "variable-offers"
              },
              children: [
                {
                  path: ':varId',
                  name: "AffiseVarId",
                  component: AffiseVars.Var
                }
              ]
            },
            {
              path: "appsflyer-link-generator",
              name: "appsflyer-link-generator",
              component: Management.AppsflyerLinkGenerator
            },
            {
              path: "adjust-link-generator",
              name: "adjust-link-generator",
              component: Management.AjdustLinkGenerator
            },
            {
              name: 'Placements',
              path: 'placements',
              component: Management.TMPlacements,
              meta: {
                permission: "placements"
              }
            },
            {
              name: 'EditTemplates',
              path: 'edit-templates',
              component: Management.EditTemplates,
              meta: {
                permission: "placements.templates"
              }
            },
            {
              name: 'EditDomainsPrefix',
              path: 'edit-domains-prefix',
              component: Management.EditDomainsPrefix,
              meta: {
                permission: "placements.domains"
              }
            },
            {
              name: 'SourcesControl',
              path: 'sources-control',
              component: SourcesControl.Home,
              permission: "source-control",
              children: [
                {
                  name: 'SourceControlAdvId',
                  path: ':advId',
                  component: SourcesControl.AdvId
                }
              ]
            },
            {
              path: "af-data",
              name: "af-data",
              component: Management.AfData.Home
            },
          ]
        },
        {
          name: 'Reports',
          path: '/reports',
          component: { render: c => c('router-view') },
          meta: {
            skip: true
          },
          children: [
            {
              name: 'ProfitReport',
              path: 'profit',
              component: Reports.ProfitReport,
              meta: {
                permission: "reports.profit"
              }
            }
          ]
        },
        {
          name: 'Redirect',
          path: '/redirect',
          component: { render: c => c('router-view') },
          meta: {
            skip: true
          },
          children: [
            {
              name: 'RedirectApptimusDsp',
              path: 'apptimus-dsp',
              component: RedirectComponent,
              props: {url: "https://dsp.apptimus.media"}
            }
          ]
        },
        {
          path: 'apps',
          name: 'Apps',
          component: { render: c => c('router-view') },
          meta: {
            skip: true,
          },
          children: [
            {
              path: "smartlinks/blacklisting",
              name: "SmartLinksBlacklisting",
              component: SmartLinks.blacklisting.Home,
              meta: {
                skip: true,
                permission: "smartlinks.blacklisting"
              },
            },
            {
              path: "smartlinks/RelatedOffers",
              name: "RelatedOffers",
              component: SmartLinks.RelatedOffers,
            },
            {
              path: "smartlinks/offerSearch",
              name: "OfferSearch",
              component: SmartLinks.OfferSearch,
            },
             {
              path: "smartlinks/SlGroups",
              name: "SmartLinksGroups",
              component: { render: c => c('router-view') },
              meta: {
                skip: true,
                permission: "smartlinks"
              },
              children:[
                {
                  path: "",
                  name: "SmartLinksGroups",
                  component: SmartLinks.SlGroups,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: ':groupId',
                  name: "SmartLinksGroupsId",
                  redirect: {name: 'SmartLinksGroupView'},
                  meta: {skip: true},
                  component: SmartLinks.GroupId,
                  children: [
                    {
                      meta: {displayBackBtn: true, goBackSlashes: 1},
                      path: '',
                      name: "SmartLinksGroupView",
                      component: SmartLinks.GroupView
                    },
                  ]
                },
                {
                  path: "/apps/smartlinks/global-offers-v2",
                  name: "GlobalOffers",
                  component: SmartLinks.GlobalOfferV2,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/global-offers",
                  name: "GlobalOffers",
                  component: SmartLinks.GlobalOffers,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/global-offers-edit",
                  name: "GlobalOffersEdit",
                  component: SmartLinks.GlobalOffersEdit,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/global-offers-advanced",
                  name: "GlobalOffersEdit",
                  component: SmartLinks.GlobalOfferAdvanced,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/global-offers-update",
                  name: "GlobalOffersUpdateRelatedOffers",
                  component: SmartLinks.GlobalOffersUpdateRelated,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/global-offers-scraper",
                  name: "GlobalOffersScraper",
                  component: SmartLinks.GlobalOfferScraper,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
                {
                  path: "/apps/smartlinks/home-global-offers-scraper",
                  name: "HomeGlobalOffersScraper",
                  component: SmartLinks.HomeGlobalOfferScraper,
                  meta: {
                    skip: true,
                    permission: "smartlinks"
                  },
                },
              ]
            }, 
            {
              path: "smartlinks/campaignsV2",
              name: "SmartLinksCampaignsV2",
              component: SmartLinks.CampaignsV2,
              meta: {
                skip: true,
                permission: "smartlinks"
              },
            },
            {
              path: "smartlinks/campaigns",
              name: "SmartLinksCampaigns",
              component: SmartLinks.Home,
              redirect: {name: 'SmartLinksCampaignsView'},
              meta: {
                skip: true,
                permission: "smartlinks"
              },
              children: [
                {
                  path: '',
                  name: "SmartLinksCampaignsView",
                  component: SmartLinks.Campaigns
                },
                {
                  path: ':campId',
                  name: "SmartLinksId",
                  redirect: {name: 'SmartLinksCampaignView'},
                  meta: {skip: true},
                  component: SmartLinks.CampId,
                  children: [
                    {
                      meta: {displayBackBtn: true, goBackSlashes: 1},
                      path: '',
                      name: "SmartLinksCampaignView",
                      component: SmartLinks.CampaignView
                    },
                    {
                      path: 'edit',
                      name: "SmartLinksCampaignEdit",
                      component: SmartLinks.CampaignEdit
                    },
                    {
                      path: 'placements/:placementId',
                      name: "SmartLinksPlacementId",
                      redirect: {name: 'SmartLinksPlacementEdit'},
                      meta: { skip: true },
                      component: SmartLinks.PlacementId,
                      children: [
                        {
                          path: '',
                          name: "SmartLinksPlacementEdit",
                          component: SmartLinks.Placement,
                          meta: {displayBackBtn: true},
                          children: [
                            {
                              meta: {displayBackBtn: true},
                              path: 'offer/:offerId',
                              name: "SmartLinksOfferId",
                              component: SmartLinks.Offer
                            }
                          ]
                        },
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: "smartlinksVTA/campaigns",
              name: "SmartLinksVTACampaigns",
              component: SmartLinks.Home,
              redirect: {name: 'SmartLinksVTACampaignsView'},
              meta: {
                skip: true,
                permission: "smartlinks"
              },
              children: [
                {
                  path: '',
                  name: "SmartLinksVTACampaignsView",
                  component: SmartLinks.VTACampaigns
                },
                {
                  path: ':campId',
                  name: "SmartLinksVTAId",
                  redirect: {name: 'SmartLinksVTACampaignView'},
                  meta: {skip: true},
                  component: SmartLinks.CampId,
                  children: [
                    {
                      meta: {displayBackBtn: true, goBackSlashes: 1},
                      path: '',
                      name: "SmartLinksVTACampaignView",
                      component: SmartLinks.CampaignView
                    },
                    {
                      path: 'edit',
                      name: "SmartLinksVTACampaignEdit",
                      component: SmartLinks.CampaignEdit
                    },
                    {
                      path: 'placements/:placementId',
                      name: "SmartLinksVTAPlacementId",
                      redirect: {name: 'SmartLinksVTAPlacementEdit'},
                      meta: { skip: true },
                      component: SmartLinks.PlacementId,
                      children: [
                        {
                          path: '',
                          name: "SmartLinksVTAPlacementEdit",
                          component: SmartLinks.Placement,
                          meta: {displayBackBtn: true},
                          children: [
                            {
                              meta: {displayBackBtn: true},
                              path: 'offer/:offerId',
                              name: "SmartLinksVTAOfferId",
                              component: SmartLinks.Offer
                            }
                          ]
                        },
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: 'EditDomainsPrefix',
              path: 'edit-domains-prefix',
              component: Management.EditDomainsPrefix,
              meta: {
                permission: "placements.domains"
              }
            },
            {
              name: 'BundleIDs',
              path: '/bundle-ids',
              component: { render: c => c('router-view') },
              meta: {
                skip: true
              },
              children: [
                {
                  path: "home",
                  name: "home",
                  component: BundleIDs.Home
                },
                {
                  path: "edit/:id",
                  name: "edit",
                  component: BundleIDs.Edit
                },
              ]
            },
            {
              name: 'Domains',
              path: '/dom',
              component: { render: c => c('router-view') },
              meta: {
                permission: "domains"
              },
              children: [
                {
                  path: 'domainsList',
                  name: "Domains",
                  component: Domains.Home
                },
                {
                  path: 'domains-view',
                  name: "domains-view",
                  component: Domains.HomeDomains
                },
                {
                  path: 'domains-edit',
                  name: "domains-edit",
                  component: Domains.DomainsEdit
                },
              ]
            },
            {
              path: "script-tags",
              name: "ScriptTags",
              component: { render: c => c('router-view') },
              meta: {
                skip: true,
                permission: "script-tags"
              },
              children: [
                {
                  path: "tag-generator",
                  name: "TagGenerator",
                  component: ScriptTags.TagGenerator.Home
                },
                {
                  path: "data-manager",
                  name: "DataManager",
                  component: ScriptTags.DataManager.Home
                },
                {
                  path: "data-manager-dsps",
                  name: "DataManagerDsps",
                  component: ScriptTags.DataManagerDsps.Home
                },
                {
                  path: "data-manager-stags-versions",
                  name: "DataManagerStagsVersions",
                  component: ScriptTags.DataManagerStagsVersions.Home
                },
                {
                  path: "data-manager-stags-versions-edit/:id",
                  name: "DataManagerStagsVersionsEdit",
                  component: ScriptTags.DataManagerStagsVersionsEdit.Home
                },
                {
                  path: "data-manager-os",
                  name: "DataManagerOs",
                  component: ScriptTags.DataManagerOs.Home
                },
                {
                  path: "data-manager-appsflyer",
                  name: "DataManagerAppsFlyer",
                  component: ScriptTags.DataManagerAppsFlyer.Home
                },
                {
                  path: "data-manager-cdn",
                  name: "DataManagerCdn",
                  component: ScriptTags.DataManagerCdn.Home
                },
                {
                  path: "data-manager-af-prt-pid",
                  name: "DataManagerAfPrtPid",
                  component: ScriptTags.DataManagerAfPrtPid.Home
                },
                {
                  path: "data-manager-var-adv",
                  name: "DataManagerVarAdv",
                  component: ScriptTags.DataManagerVarAdv.Home
                },
                {
                  path: "data-manager-operation-csm",
                  name: "DataManagerOperationCsm",
                  component: ScriptTags.DataManagerOperationCsm.Home
                },
                {
                  path: "data-manager-geo-list",
                  name: "DataManagerGeoList",
                  component: ScriptTags.DataManagerGeoList.Home
                },
                {
                  path: "advertisers-manager",
                  name: "AdvertiseManagerHome",
                  component: ScriptTags.AdvertiserPage.Home
                },
                {
                  path: "add-advertisers",
                  name: "AddAdvertise",
                  component: ScriptTags.AddAdvertise.Home
                },
                {
                  path: "data-manager-vertical",
                  name: "DmVertical",
                  component: ScriptTags.DataManagerVertical.Home
                },
                {
                  path: "data-manager-edit-os/:id",
                  name: "DataManagerEditOs",
                  component: ScriptTags.DataManagerEditOs.Home
                },
                {
                  path: "data-manager-edit-os-version/os/:os",
                  name: "DataManagerEditOsVersion",
                  component: ScriptTags.DataManagerEditOsVersion.Home
                },
                {
                  path: "data-manager-edit-dsp/:id",
                  name: "DataManagerEditDsp",
                  component: ScriptTags.DataManagerEditDsp.Home
                },
                {
                  path: "data-manager-bundle-wl-details",
                  name: "DataManagerBundleWlDetails",
                  component: ScriptTags.DataManagerBundleWlDetails.Home
                },
                {
                  path: "data-manager-bundle-wl",
                  name: "DataManagerBundleWl",
                  component: ScriptTags.DataManagerBundleWl.Home
                },
                {
                  path: "data-manager-af-app-name",
                  name: "DataManagerAfAppName",
                  component: ScriptTags.DataManagerAfAppName.Home
                },
                {
                  path: "wl",
                  name: "ScriptTagsWl",
                  component: ScriptTags.Wl
                },
                {
                  path: "h",
                  name: "ScriptTagsHome",
                  component: ScriptTags.Home,
                },
                {
                  path: "discrepancy-report",
                  name: "DiscrepancyReport",
                  component: ScriptTags.DiscrepancyReport
                },
                {
                  path: "stags-req-report",
                  name: "RequestReport",
                  component:ScriptTags.RequestReport,
                  meta: {
                    permission: "stags.tag-monthly-report"
                  },

                },
                {
                  path: "bl",
                  name: "ScriptTagsBl",
                  component:ScriptTags.Bl,

                },
                {
                  path: ":campId",
                  name: "ScriptTagsCamp",
                  component: ScriptTags.Camp,
                }
              ]
            },
          ]
        },
      ]
    },
    {
      path: '/test',
      component: TestPage
    },
    {
      name: 'Unavailable',
      path: '/unavailable',
      component: Unavailable,
      beforeEnter: (to, from, next) => {
        if(state.version !== 'ERROR') {
          next('/')
        } else {
          next(true)
        }
      }
    },
    {
      path: '*',
      component: Page404
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(state.version !== null) {
    return next(true)
  }
  Vue.http.get('version')
    .then(r => r.json())
    .then(r => r.data)
    .then(data => {
      state.version = data
      next(true)
    })
    .catch(err => {
      state.version = 'ERROR'
      next({name: 'Unavailable' })
    })
})

router.beforeEach((to, from, next) => {
  if(state.user !== null) {
    return next(true)
  }
  Vue.http.get('auth')
    .then(r => r.json())
    .then(data => {
      state.user = data.user
      if(data.token) {
        localStorage.setItem('token', data.token)
        window.setCookie('token', data.token, 365*10)
      }
    })
    .catch(err => {
      state.user = false
    })
    .then(() => {
      state.initialLoad = true
      next(true)
    })
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => typeof record.meta.authed !== "undefined" && record.meta.authed !== !!state.user)) {
      return next({name: !!state.user ? 'Home' : 'Login'})
  }
  next(true)
})

export default router
