export default {
  
  Auth: {
    login: "auth/login",
    logout: "auth/logout",
    signup: ""
  },
  Admin: {
    users: 'admin/users',
    getUserInfo: userID => `admin/users/${userID}`,
    editUser: userID => `admin/users/${userID}`,
    editPassword: userID => `admin/users/pass/${userID}`,
    deleteUser: userID => `admin/users/${userID}`,
    getRolePerms: roleID => `admin/roles/${roleID}`,
    editRole: roleID => `admin/roles/${roleID}`,
    newRole: 'admin/roles',
    newPerm: 'admin/perms',
    deletePerm: permID => `admin/perms/${permID}`,
    deleteRole: roleID => `admin/roles/${roleID}`,
    editPerm:  permID => `admin/perms/${permID}`,
    releseUser: userID => `admin/users/${userID}/release-user`
  },
  Management: {
    tmPlacements: "management/tag-mahal/placements",
    tmDomainPrefixes: "management/tag-mahal/domain-prefixes",
    tmTMPixels: v => "management/tag-mahal/tmpixels/" + v,
    tmEditPlacement: v => "management/tag-mahal/placements/" + v,
    tmDuplicatePlacement: v => "management/tag-mahal/placements/" + v + "/duplicate",
    tmTemplates: "management/tag-mahal/templates",
    tmTemplate: v => "management/tag-mahal/templates/" + v,
    tmDelDomainPrefix: v => "management/tag-mahal/domain-prefixes/" + v,
    tmRemovePixels: "management/tag-mahal/pixels",
    affiseAdvertiserWhitelisting: "management/affise/whitelisting",
    pidList: "pid",
    newPid: "osVersion/pid",
    pidListIdToName: "pid-lists",
    deletePidFromList: pidID => `osVersion/pid/${pidID}/delete`,
    updatePidComment: pidID => `osVersion/pid/${pidID}`,
    newPidlist: `newPid-list`,
    deletePidList: listID => `pid/list/${listID}`,
    getOsVersions: osType => `osVersion/${osType}`,
    addPidToBank: `pidBank/addPidToBank`,
    getPidsBank: `pidBank/getPidsPool`,
    createPidsBankList: `pidBank/createPidsBankList`,
    getPidsBankList: `pidBank/getPidsBankList`,
    deletePidsBankList: `pidBank/deletePidsBankList`,
    getOffersRelatedCount: `pidBank/getOffersRelatedCount`,
    deletePid: `pidBank/deletePid`,
    getPidsListDetails: `pidBank/getPidsListDetails`,
    removePidFromList: `pidBank/removePidFromList`,
    coolDownPid: `pidBank/coolDownPid`,
    pidPoolReplace: `pidBank/replacePid`,
    addPidsToList: `pidBank/addPidsToList`,
    addBulk: `pidBank/addBulk`,
    pidListNameUpdate: `pidBank/pidListNameUpdate`,
    saveBundleList: `bundlePool/saveBundleList`,
    saveBundleRow: `bundlePool/saveBundleRow`,
    getListData: `bundlePool/getListData`,
    deleteBundle: `bundlePool/deleteBundle`,
    getBundlesLists: `bundlePool/getBundlesLists`,
    deleteBundleList: `bundlePool/deleteBundleList`,
    duplicateBundleList: `bundlePool/duplicateBundleList`,
    bundlesBulkUpload: `bundlePool/bundlesBulkUpload`,
    bundlesSearch: `bundlePool/bundlesSearch`,
    downloadBundleList: (id) => `bundlePool/downloadBundleList/${id}`,
    getServiceUrl: (serviceName, apiCall) => `proxy/${serviceName}/${apiCall}`,
    getTgBaseData: `tg/getTgBaseData`,
    getTgStagData: `tg/getTgStagData`,
    tgGenerate: `tg/tgGenerate`,
    tgSaveDsp: `tg/tgSaveDsp`,
    tgGetDsp: `tg/tgGetDsp`,
    tgDeleteDsp: `tg/tgDeleteDsp`,
    getTgSsps: `tg/getTgSsps`,
    getTgDomains: `tg/getTgDomains`,
    tgSaveOs: `tg/tgSaveOs`,
    tgSaveOsVersion: `tg/tgSaveOsVersion`,
    getTgOsVersionsData: `tg/getTgOsVersionsData`,
    tgOsVersionDelete: `tg/tgOsVersionDelete`,
    tgStagVersionSave: `tg/tgStagVersionSave`,
    tgGetStagVersions: `tg/tgGetStagVersions`,
    tgDeleteStagVersions: `tg/tgDeleteStagVersions`,
    tgGetStagVersion: `tg/tgGetStagVersion`,
    getAfData: `tg/getAfData`,
    savAfRow: `tg/savAfRow`,
    deleteAfRow: `tg/deleteAfRow`,
    getAfStatsData: `smartlinks/getAfData`,
    getCdnData: `tg/getCdnData`,
    saveDmCdn: `tg/saveDmCdn`,
    deleteDmCdn: `tg/deleteDmCdn`,
    getDmOms: `dm/getDmOms`,
    saveDmOm: `dm/saveDmOm`,
    deleteDmOm: `dm/deleteDmOm`,
    updateDmSlOm: `dm/updateDmSlOm`,
    replaceAllByListId: `pidBank/replaceAllByListId`,
    syncPostback: `pidBank/syncPostback`,
    getAfPart: `dm/getAfPart`,
    saveAfPart: `dm/saveAfPart`,
    deleteAfPart: `dm/deleteAfPart`,
    getPid: `dm/getPid`,
    savePid: `dm/savePid`,
    dmDeletePid: `dm/deletePid`,
    getCsmList: `csm/getCsmList`,
    saveCsm: `csm/saveCsm`,
    deleteCsm: `csm/deleteCsm`,
    saveAdvertiser: `advertiser/saveAdvertiser`,
    getAdvertisersList: `advertiser/getAdvertiserList`,
    getAdvertisers: id => `advertiser/getAdvertiser/${id}`,
    saveAdvertiserLogo: `advertiser/saveAdvertiserLogo`,
    deleteAdvertiser: id => `advertiser/deleteAdvertiser/${id}`,
    validateAffiseAdvertiser: `advertiser/validateAffiseAdvertiser`,
    getGlobalOffersByAdvertise: (advId, isVta) => `globalOffer/fetchGlobalOffers/${advId}/${isVta}`,
    assocGlobalOfferToAdSet: (offerId, adSetId) => `globalOffer/assocGlobalOffer/${offerId}/${adSetId}`,
    getVarAdvData: `adv/getVarAdv`,
    saveVarAdv: `adv/saveVarAdv`,
    fixLiveNoAssoc: `pidBank/fixLiveNoAssoc`,
    addBundlesBulk: `dm/addBundlesBulk`,
    getBundlesWl: `dm/getBundles`,
    deleteBundleWl: `dm/deleteBundles`,
    filterBundles: `dm/filterBundles`,
    getAfAppName: `dm/getAfAppName`,
    saveAfAppName: `dm/saveAfAppName`,
    deleteAfAppName: `dm/deleteAfAppName`,
    setStatusByAfAccount: `smartlinks/setStatusByAfAccount`,
    getMediaAnalystManagers: `dm/getMediaAnalystManagers`,
    saveMediaAnalystManager: `dm/saveMediaAnalystManager`,
    deleteMediaAnalystManager: `dm/deleteMediaAnalystManager`,
    getGeoLists: `dm/getGeoLists`,
    saveGeoList: `dm/saveGeoList`,
    deleteGeoList: `dm/deleteGeoList`,
    getMediaAnalystByOsCountry: `dm/getMediaAnalystByOsCountry`,
    downloadBundleList: id => `bundlePool/downloadBundleList/${id}`,
    rotateOfferNow: `smartlinks/rotateOfferNow`,
    getDmVerticals: `dm/getDmVertical`,
    saveDmVertical: `dm/saveDmVertical`,
    deleteDmVertical: `dm/deleteDmVertical`,
    getAlternateSeat: `dm/getAlternateSeat`,
    saveAlternateSeat: `dm/saveAlternateSeat`,
    deleteAlternateSeat: `dm/deleteAlternateSeat`,
    addScheduler: `dsp-schedule/save-scheduler`,
    getSchedulerList: `dsp-schedule/get-scheduler-list`, 
    getSchedulerById: id => `dsp-schedule/get-scheduler/${id}`,
    deleteScheduler: (id) => `dsp-schedule/delete-scheduler/${id}`,
    getSchedulerMetadata: `dsp-schedule/get-scheduler-options-metadata`,
  },
  SmartLinksCampaigns: {
    getCampaigns: 'smartlinks/campaigns',
    postCampaign: 'smartlinks/campaigns',
    rotateOfferNow: `rotateNow`,
    setCampaignStatus: campId => `smartlinks/campaigns/${campId}/set-status`,
    setForcedCampaignStatus: campId => `smartlinks/campaigns/${campId}/set-forced-status`,
    updateCampaign: campId => `smartlinks/campaigns/${campId}`,
    smartLinksCampaignGetBL: campId => `smartlinks/campaigns/${campId}/bl`,
    smartLinksCampaignData: campId => `smartlinks/campaigns/${campId}`,
    smartLinksCampaignGetBL: campId => `smartlinks/campaigns/${campId}/bl`,
    getRequestBlockedsReport: campId => `smartlinks/campaigns/${campId}/get-requests-blocked-report`,
    smartLinksUpdateCampaignOrder: campId => `smartlinks/campaigns/${campId}/placements_order`,
    postCampaignBl: campId =>  `smartlinks/campaigns/${campId}/blacklisting`,
    postPlacement: campId => 'smartlinks/campaigns/' + campId + '/placements',
    deletePlacement: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}`,
    updatePlacement: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}`,
    getWaterfalls: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}/waterfalls`,
    postWaterfall: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}/waterfalls`,
    updateWaterfall: (campId, placementId, waterfallId) => `smartlinks/campaigns/${campId}/placements/${placementId}/waterfalls/${waterfallId}`,
    smartLinksUpdateWaterfallOrder: (campId, placementId, waterfallId) => `smartlinks/campaigns/${campId}/placements/${placementId}/waterfalls/${waterfallId}/items_order`,
    getOffers: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}/offers`,
    postOffer: (campId, placementId, waterfallId) => `smartlinks/campaigns/${campId}/placements/${placementId}/offers`,
    updateOffer: (campId, placementId, offerId) => `smartlinks/campaigns/${campId}/placements/${placementId}/offers/${offerId}`,
    updateOfferDirect: (offerId) => `smartlinks/offers/${offerId}`,
    deleteOfferWithoutOfferID: (campId, placementId) => `smartlinks/campaigns/${campId}/placements/${placementId}/offers/`,
    deleteOfferByOfferID: (campId, placementId, offerId) => `smartlinks/campaigns/${campId}/placements/${placementId}/offers/${offerId}`,
    searchOffers: 'offers/search',
    filterOffersByNameOrID: 'filterRelevantOffers',
    slStatsReport: 'smartlinks/do-report',
    slStatsDownloadReport: v => `smartlinks/report/excel/${v}`,
    getISPs: `smartlinks/tools/isps`,
    // Groups
    getSlGroups: 'smartlinks/campaignsGroups',
    setGroupStatus: groupId =>`smartlinks/campaignsGroups/${groupId}/set-status`,
    setGroupActiveScheduler: groupId =>`smartlinks/campaignsGroups/${groupId}/set-active-scheduler`,
    setGroupActive: groupId =>`smartlinks/campaignsGroups/${groupId}/set-active`,
    newGroup:'smartlinks/campaignsGroups/newGroup',
    setCampaignGroup: campId => `smartlinks/campaigns/${campId}/set-group`,
    deleteGroupById: groupId => `smartlinks/campaignsGroups/${groupId}`,
    updateGroupName: groupId =>`smartlinks/campaignsGroups/${groupId}`,
    createGroupScheduler: groupId => `smartlinks/campaignsGroups/${groupId}/scheduler`,
    fetchGroupSchedulerById: groupId => `smartlinks/campaignsGroups/${groupId}/scheduler`,
    fetchGroupRelatedCamps: groupId => `smartlinks/groupCampaignById/${groupId}`,
    pauseResetOffer: `smartlinks/pauseResetOffer`,
    newSlRequestsBlockedReport: campId => `${campId}/get-requests-blocked-report/v2`,
  },
  GlobalOffers : {
    createGlobalOffer: `globalOffer/createGlobalOffer`,
    fetchGlobalOffers: `globalOffer/fetchGlobalOffers`,
    fetchGlobalOffersV2: `globalOffer/fetchGlobalOffersV2`,
    fetchGlobalOfferById: offerId => `globalOffer/fetchGlobalOffers/${offerId}`,
    updateGlobalOffer: offerId => `globalOffer/editGlobalOffer/${offerId}`,
    deleteGlobalOfferById: offerId =>`globalOffer/deleteGlobalOffer/${offerId}`,
    duplicateGlobalOffer: offerId => `globalOffer/duplicateGlobalOffer/${offerId}`,
    resetGlobalOfferRelatedSetting: `globalOffer/resetGlobalOfferRelatedSetting`,
    fetchRelatedOffers: offerId => `globalOffer/fetchRelatedOffers/${offerId}`,
    updateOffersScraperData: `globalOffer/updateOffersScraperData`,
    setScraperStatus: `globalOffer/setScraperStatus`,
    validateBundleId: `globalOffer/validateBundleId`,
  },
  Domains:{
    getDomains: 'dom/domains',
    postDomains: 'dom/domains',
    sendUpdateSSLMail: 'dom/domains/sslEndDate',
    deleteDomains:(domId) => `dom/domains/${domId}/delete`,
    setDomainEdit:(domId) => `dom/domains/${domId}`,
    getDomainsOptions: 'dom/getDomainsOptions',
    getSmartlinksDomains: `dom/get-smartlinks-domains`,
    getSmartlinksDomainbyId: 'dom/get-smartlinks-domain-by-id',
    saveSmartlinksDomain: 'dom/save-smartlinks-domain',
    getUsedCdns: 'dom/get-used-cdns'
  },
  ScriptTags: {
    getCampaigns: "stags",
    createCampaign: 'stags',
    requestReport: "stags/stags-calls-report",
    regenerateTagID: stagId => `stags/${stagId}/regenerate-id`,
    getCampLinks: stagId => `stags/${stagId}`,
    generateBlockedReport: stagId => `stags/${stagId}/get-blocked-report`,
    generateRequestsReport: stagId => `stags/${stagId}/get-requests-report`,
    campLinks: stagId => `stags/${stagId}/links`,
    updateCampOrder: stagId => `stags/${stagId}/links-order`,
    createCampLink: stagId => `stags/${stagId}/links`,
    getBanners: "stags/banners",
    uploadBanner: "stags/banners",
    updateBanner: id => `stags/banners/${id}`,
    deleteBanner: id => `stags/banners/${id}`,
    ssps: "stags/ssps",
    wlData: "wl-data",
    discrepancyReport: "stags/discrepancy-report",
    bundleIdBl:"stags/bundle-id-blacklist",
    deleteBundleIdBl: id => `stags/bundle-id-blacklist/${id}`,
  },
  BundleIDs:{
    getBundleData: `bundleIds/getBundleData`,
    getBundleList: `bundleIds/getBundleList`,
    saveBundle: `bundleIds/saveBundle`,
    deleteBundle: `bundleIds/deleteBundle`,
  },
  Reports: {
    Profit: {
      notFetchedTimes: "reports/profit/notfetched",
      profitReport: "reports/profit"
    }
  },
  affiseVars: {
    vars: "management/var-offers",
    varById: id => "management/var-offers/" + id
  },
  sourcesControl: {
    advs: "management/sources/avtiser",
    advSources: v => "management/sources/avtiser/" + v
  },
  blacklisting: {
    fileUpload: "smartlinks/blacklisting/upload-file",
    getFiles: "smartlinks/blacklisting",
    downloadFile: v => `smartlinks/blacklisting/files/${v}`,
    deleteFile: v => `smartlinks/blacklisting/files/${v}`,
    offerBlFiles: v => `smartlinks/blacklisting/offer/${v}/`
  },
  Dash: {
    getData: `dash/get_data`,
    getDataGlobal: `dash/get_data_global`,
    saveOverrides: `dash/save_overrides`,
    getPresets: `dash/get_presets`,
    getPreset: `dash/get_preset`,
    getPresetHistory: `dash/get_preset_history`,
    deletePreset: `dash/delete_preset`,
    getAfSeat: `dash/get_af_seat`
  }
}
