<template lang="html">
    <b-modal v-model="showCostsModal" title="Cost Breakdown"  @hide=" $emit('update:is-show', false)" hide-footer>
      <div>
        <table class="tbl_sum">
            <tr>
                <td class="td_s">
                    <h4>
                        Media Cost:<br/> {{ summary_data.media_cost }} $
                    </h4>
                </td>
                <td class="td_s">
                    <h4>
                        Serving Cost:<br/> {{ summary_data.total_serving_cost }} $
                    </h4>
                </td>
            </tr>
            <tr>
                <td class="td_s">
                    Apptimus: {{ summary_data.apptimus_cost }} $<br/>
                    Bucksense: {{ summary_data.bucksense_cost }} $<br/>
                    Kayzen: {{ summary_data.kayzen_cost }} $<br/>
                    Geospot: {{ summary_data?.geospot_cost }} $<br/>
                </td>
                <td class="td_s">
                    Impressions: {{ summary_data.impressions_serving_cost }} $<br/>
                    Conversions: {{ summary_data.conversions_serving_cost }} $<br/>
                </td>
            </tr>
        </table>
      </div>
    </b-modal> 
</template>
<script>
    
export default {
    
    props: ['isShow', 'summary_data'],
    data() {
        return {

        }
    },
    created(){
    },
    computed: {
        showCostsModal: {
            get: function() {
                return this.isShow
            },
            set: function() {

            }
        }
    },
    watch: {
        summary_data: function(val) {
            val.media_cost = this.formatNumber(val.media_cost);
            val.total_serving_cost = this.formatNumber(val.total_serving_cost);
            val.impressions_serving_cost = this.formatNumber(val.impressions_serving_cost);
            val.conversions_serving_cost = this.formatNumber(val.conversions_serving_cost);
            val.apptimus_cost = this.formatNumber(val.apptimus_cost);
            val.bucksense_cost = this.formatNumber(val.bucksense_cost);
            val.kayzen_cost = this.formatNumber(val.kayzen_cost);
            val.geospot_cost = this.formatNumber(val.geospot_cost);
            return val;
        }
    },
    methods: {
        formatNumber(inp) {
            inp = Math.round(inp);
            inp = inp.toLocaleString();      
            console.log('inp', inp);
            return inp;
        }
    },    
}
</script>
<style scoped>
.tbl_sum {
    margin: 0 auto;
}

.td_s {
    padding: 10px;
    text-align: center;
}
</style>