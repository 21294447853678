<template>
  <ol class="breadcrumb">
    <!-- <li class="breadcrumb-item" v-for="(item, index) in list_1">
      <span class="active" v-if="isLast(index)">{{ showName(item) | seperateWords | removeFirstWord }}</span>
      <router-link :to="item" v-else>{{ showName(item) }}</router-link>
    </li> -->
    <i v-if='displayBackButton' @click='goBack' class="fa fa-chevron-circle-left back-btn-2" aria-hidden="true"></i>
  </ol>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  filters: {
    removeFirstWord(str) {
      let words = str.trim().split(' ')
      if (words.length > 1) {
        return words.slice(1).join(' ')
      }
      return str
    }
  },
  computed: {
    list_1() {
      let a = this.list
      return a.filter((v, index) => (!v.meta || !v.meta.skip))
    },
    displayBackButton() {
      let meta = this.$route.meta
      return meta && meta.displayBackBtn
    }
  },
  methods: {
    goBack() {
      let fullPath = this.$route.fullPath
      let len = fullPath.length
      let cb = 0
      let meta = this.$route.meta
      let slashes = (meta && meta.goBackSlashes) ? meta.goBackSlashes : 2

      // check if current path is a campaign path = apps/smartlinks/campaigns/id and previous path is campaigns or campaignsV2
      const smartlinksV2Regex = /^\/apps\/smartlinks\/campaigns\/\d+$/;
      if (smartlinksV2Regex.test(fullPath)) {
        const lastSlashIndexPreviousPath = document.referrer.lastIndexOf('/');
        const campaignsVersion = document.referrer.slice(lastSlashIndexPreviousPath + 1);
        fullPath = fullPath.replace('campaigns', campaignsVersion);
      }

      for(let i = 2; i < len; i++) {
        if(fullPath[len - i] === '/') {
          cb++
          if(cb === slashes) {
            this.$router.push(fullPath.substr(0, len - i));
            return
          }
        }
      }
    },
    isLast (index) {
      return index === this.list_1.length - 1
    },
    showName (item) {
      let t1
      if (item.meta && item.meta.label) {
        t1 = item.meta.label
      }
      else if (item.name) {
        t1 = item.name
      }
      return t1
    }
  },
  created() {
  }
}
</script>

<style scoped>
.back-btn-2 {
  font-size: 41px;
  color: #3b67a0;
  cursor: pointer;
}
</style>
